import Vue from 'vue'
import getEmoji from 'get-random-emoji'
import { decimalFix, toFixedNoRound } from './utils'
import ObjectID from 'bson-objectid'

Vue.filter('tradeCurrency', function (value, count) {
  if (!value) return '0.00'

  if (typeof count === 'number' && count >= 0)
    return toFixedNoRound(value, count)

  return String(Number(value))
})

Vue.filter('mongoIdToNumber', function (value) {
  return String(ObjectID(value)?.getTimestamp()?.valueOf() || '').slice(0, -5)
})

Vue.filter('hideCurrency', function (value, count = 4, hide = true) {
  if (!hide) return value

  return new Array(count)
    .fill(0)
    .map(e => getEmoji())
    .join(' ')
})

Vue.filter('volume', function (value) {
  if (typeof value == 'string') value = Number(value)

  if (typeof value !== 'number') return value

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value).replace('$', '')
})

Vue.filter('currency', function (value) {
  if (typeof value == 'string') value = Number(value)

  if (typeof value !== 'number') return value

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
})

Vue.filter('nameToPair', function (value) {
  if (!value || typeof value != 'string') return ''
  return value.replace('USDT', '/USDT')
})

Vue.filter('getCurrencyFromPair', function (value) {
  if (!value || typeof value != 'string') return ''
  return value.replace('/USDT', '').replace('USDT', '')
})

Vue.filter('currencySymbol', function (value) {
  if (typeof value == 'number') value = String(value)

  return `$${value}`.replace('$-', '-$')
})

Vue.filter('fileUrl', function (value) {
  return value && value !== 'null'
    ? `${process.env.VUE_APP_API_URL}/uploads/${value}`
    : ''
})
