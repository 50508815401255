import http from '../../http'

// initial state
const state = {
  traders: [],
  allPairs: [],
}

// getters
const getters = {
  getTraders: state => state.traders,
  getTradersWithActivePair: state => pair => {
    /*  const coinsArray = state.traders.map(item => item.pairs)
    console.log('coinsArray', this.getTraders)
    const getFirstCoins = pairs => {
      return pairs.slice(0, 3)
    }*/
    return state.traders
  },
  getPairs: state => state.allPairs,
  getFirstPair: state => state.allPairs[0].name,
}

// actions
const actions = {
  /**
   * Get all traders
   */
  getAllTraders({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get(`traders`)
        .then(({ data }) => {
          commit('setAllTraders', data)
        })
        .catch(reject)
    })
  },

  getAllPairs({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('finance/pairs/available')
        .then(({ data }) => {
          commit('setAllPairs', data)
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  setAllTraders(state, data) {
    state.traders = data
  },
  setAllPairs(state, data) {
    state.allPairs = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
