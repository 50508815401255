export function runAtTimestamp(timestamp, cbk) {
  // ||  !(timestamp instanceof Date)
  if (typeof timestamp != 'number')
    throw 'timestamp should be a valid Date object'

  let ms = timestamp - new Date().getTime()
  if (ms < 0) throw 'Too late!!'
  return setTimeout(cbk, ms)
}

export function getTimezoneGMTOffset() {
  const timeZone = process.env.VUE_APP_TIME_OFFSET
  const now = new Date()
  const tzString = now.toLocaleString('en-US', { timeZone })
  const localString = now.toLocaleString('en-US')
  const diff = (Date.parse(localString) - Date.parse(tzString)) / 3600000
  const offset = diff + now.getTimezoneOffset() / 60

  return -offset
}

export function calculatePercent(a, b) {
  let res = Math.abs(((b - a) / a) * 100).toFixed(2)

  if (isNaN(res) || res == Infinity) return undefined
  return res
}

export const downloadFileByBlob = (buffer, name, type) => {
  const url = window.URL.createObjectURL(new Blob([buffer]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
}

export const decimalFix = (value, count = 2) => {
  return value?.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] || ''
}

export const toFixedNoRound = (value, precision = 2) => {
  const factor = Math.pow(10, precision)
  return Math.floor(value * factor) / factor
}
